<template>
  <div class="list-item" @click="navToPage(item.no)">
    <van-row>
      <van-col span="12">{{item.no}}</van-col>
      <van-col span="12">{{item.sname}}</van-col>
    </van-row>
    <van-row>
      <van-col span="24">{{item.pname}}</van-col>
    </van-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ListItem",
  props: {
    item: Object,
    navTo: String
  },
  computed: {
    ...mapState({
      dd: state => state.dd.dd
    })
  },
  methods: {
    navToPage(no) {
      this.$router.push({ name: this.navTo, params: { no: no } });
    }
  }
};
</script>

<style lang="scss">
.list-item {
  padding: 5px 0;
}
</style>
