import http from '@/utils/http'
export default {
  projectlist: (page, id) => {
    return http.get('projectlist', {
      page,
      id
    }, true)
  },
   projectcount: () => {
     return http.get('projectcount', {})
   },
}
